<template>
    <div class="van-date-pick">
        <van-cell
            :is-link="!disabled"
            :title="label"
            @click="show = true"
            :placeholder="placeholder"
            :value="displayName"/>
        <van-popup v-if="!disabled" v-model="show" position="bottom">
            <van-datetime-picker
                :value="proxyValue"
                :type="dataType"
                v-bind="{...pickerOptions}"
                :title="placeholder"
                :disabled="disabled"
                @confirm="Change"
                :minDate="new Date(1970, 1, 1)"
                :maxDate="new Date(2099, 12, 31)"
                @cancel="show = false"
            />
        </van-popup>

    </div>
</template>

<script>
    import dayjs from 'dayjs'

    export default {
        name: 'vanDatePick',
        props: {
            label: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            pickerOptions: {
                type: Object,
                default: () => {
                },
            },
            value: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: '选择日期',
            },
            dataType: {
                type: String,
                default: 'date',
            },
            formatter: {
                type: String,
                default: 'YYYY-MM-DD',
            },
        },
        data() {
            return {
                show: false,
            }
        },
        created() {
        },
        computed: {
            proxyValue: {
                get () {
                    if (this.value) {
                        return new Date(this.value)
                    }
                    return new Date()
                },
                set (v) {
                    this.$emit('input', dayjs(v).format(this.formatter))
                }
            },
            displayName() {
                return  this.value ? typeof this.value === 'string' ? this.value : dayjs(this.value).format(this.formatter) : this.placeholder
            }
        },
        watch: {
        },
        methods: {
            Change(v) {
                this.proxyValue = v
                this.show = false
            },
        },
    }
</script>

<style scoped>
    .van-date-pick {
        width: 100%;
    }
</style>

