<template>
    <van-field
        :value="observableValue"
        :class="[isRequired ? 'required': null  , 'form-element']"
        :rules="rules"
        :show-error="false"
        error-message-align="right">
        <template #input>
            <slot></slot>
        </template>
    </van-field>
</template>

<script>
    export default {
        name: 'FormElement', //
        directives: {},
        components: {
        },
        mixins: [],
        data() {
            return {}
        },
        props: {
            item: {
                type: Object
            },
            required: Boolean,
            value: {},
            rules: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        computed: {
            formMode() {
                return this.props.mode || this.$attrs.mode
            },
            // 查看态
            isViewModel() {
                return this.formMode === 'view'
            },
            observableValue() {
                if (Array.isArray(this.value)) {
                    return this.value.length ? Math.random() : undefined;
                }
                if (Object.prototype.toString.call(this.value) === '[object Object]') {
                    return JSON.stringify(this.value)
                }
                return this.value
            },
            isRequired () {
                return this.rules.some(item => item.required)
            }
        },
        filters: {},
        methods: {
        },
        watch: {},
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        beforeUpdate() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        errorCaptured() {
        },
    }
</script>

<style lang="scss">
    .form-layouts-root {
        ::v-deep .van-field__error-message {
            text-align: right;
            padding-right: 20px;
        }
    }

    .add-grounp {
        display: flex;
        align-items: stretch;

        .grounp-icon {
            width: 60px;
            font-size: 30px;
            color: #fff;
            background: #ee0a24;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px #eee solid;
        }
    }

    .form-element {
        position: relative;
       .van-field--error .van-field__control::placeholder {
           color: #c4c5c8;
       }
       &.van-field--error .van-field__control::placeholder {
           color: #c4c5c8;
       }
       .van-field__control > div{
           width: 100%;
       }
       &::after{
           content: none;
       }
       .van-cell::after{
           left: 0;
           right: 0;
       }
       &.required {
           &::before{
               content: '*';
               color: #ee0a24;
               position: absolute;
               left: 8px;
               top: 12px;
               z-index: 11;
           }
       }
    }
</style>
