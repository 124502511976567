import {BaseComponentNames} from './declare'

import { default as  Select} from './Select'

import { default as Input } from './Input'

import { default as Title } from './Title'

import { default as Time } from './Time'

import { default as Txt } from './Txt'

import { default as RadioGroup } from './RadioGroup'

// import { default as Uploader } from './Uploader'

import { default as Address } from './Address'

import { default as FormFieldText } from './FormFieldText'

import { default as NumberRange } from './NumberRange'

export default {
  [BaseComponentNames.Txt]: Txt,
  [BaseComponentNames.Select]: Select,
  [BaseComponentNames.Input]: Input,
  [BaseComponentNames.Title]: Title,
  // [BaseComponentNames.Uploader]: Uploader,
  [BaseComponentNames.Time]: Time,
  [BaseComponentNames.RadioGroup]: RadioGroup,
  [BaseComponentNames.Address]: Address,
  [BaseComponentNames.Txt]: Txt,
  [BaseComponentNames.FormFieldText]: FormFieldText,
  [BaseComponentNames.NumberRange]: NumberRange,
}

