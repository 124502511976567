<template>
        <van-field
            v-model="proxyValue"
            :label="label"
            :disabled="disabled"
            :clearable="clearable"
            :maxLength="maxLength"
            :type="inputType"
            :show-error="false"
            :placeholder="placeholder ? placeholder : `请输入${label}`"
            :show-word-limit="showWordLimit"
            :rules="rules"
            @blur="handlerInput"
            class="v-input"/>
</template>

<script>
export default {
    name: 'VanInput',
    props: {
        label: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showWordLimit: {
            type: Boolean,
            default: false
        },
        maxLength: {
            type: Number,
            default: 500
        },
        inputType: {
            type: String,
            default: 'text'
        },
        clearable: {
            type: Boolean,
            default: true
        },
        value: {
            type: [String, Number],
            default: ''
        },
        rules: {
          type: Array,
          default() {
            return []
          }
        },
        placeholder: {
          type: String,
          default: ''
        },
        decimalPlaces: {
            type: Number,
            default: 2
        },
        max: {
            type: Number
        }
    },
    data() {
        return {
        };
    },
    computed: {
        proxyValue: {
            get () {
                return this.value
            },
            set (v) {
                this.$emit('input', v)
            }
        }
    },
    methods: {
        handlerInput () {
            if (this.inputType === 'number') {
                this.proxyValue = this.formatNumberTwoDecimalPlacesAndCommas(this.proxyValue)
                if (this.max && this.proxyValue > this.max) {
                    this.proxyValue = this.max
                }
            }
        },
        formatNumberTwoDecimalPlacesAndCommas(value) {
            const times = this.decimalPlaces ? Math.pow(10, this.decimalPlaces) : 1
            // took this from: http://blog.tompawlak.org/number-currency-formatting-javascript
            return (Math.round(value * times) / times).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
        }
    },
    mounted() {
    }
}
</script>


<style lang="scss">
.v-input.van-field{
    input{
        text-align: right
    }
}
</style>

