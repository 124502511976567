export const BaseComponentNames = {
  Select : "Select",
  Input : "Input",
  Title : "Title",
  Time : "Time",
  SelectCard : 'SelectCard',
  SelectWithIndexAnchor : "SelectWithIndexAnchor",
  SelectWithPupop : "SelectWithPupop",
  Uploader : "Uploader",
  RadioGroup : "RadioGroup",
  SelectWithCard : "SelectWithCard",
  Txt : "Txt",
  FormFieldText : "FormFieldText",
  Address : "Address",
  // 子表的标记
  Detail : "add",
  // 附件，针对附件做特殊组件处理
  Attachments : "Attachments",
  // 数字区间
  NumberRange : "NumberRange",
}
