<template>
<div>
    <van-cell :title="label" :value="proxyValue" />
</div>

</template>

<script>
export default {
    name: 'Txt',
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number, Object]
        }
    },
    computed: {
        proxyValue () {
            console.log(this.value)
            if (this.value && typeof this.value === 'object') {
                return this.value.title
            }
            return this.value
        }
    },
    mounted() {
        console.log(this.formField)
    }
}
</script>
