<script>
import FormElement from './formElement'
import Components from '@/components/baseComponents'
import {BaseComponentNames} from '@/components/baseComponents/declare'
export default {
  name: 'form-box',
  components: {
    FormElement,
    ...Components,
  },
  props: {
    formList: {
        type: Array,
    },
    formData: {
      type: Object,
      default: () => {
          return {}
      },
    },
    showAction: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'add'
    },
  },
  computed: {
    formMode() {
      return this.mode
    },
    // 查看态
    isViewModel() {
      return this.formMode === 'view'
    },
  },
  data () {
    return {}
  },
  methods: {
    renderComponent() {
        return this.formList.filter(item => {
            // 当前form模式匹配 并且显示
            const matchModel = !item.modes || item.modes.includes(this.formMode)
            const isShowItem = typeof item.show === 'function' ? item.show(this.formData) : (item.show || item.show === undefined)
            return matchModel && isShowItem
        }).map(item => {
          return this.renderFormItem(item)
        })
    },
    renderShowAction() {
        if (this.showAction) {
            return (
                <div style="margin: 16px;">
                    <van-button round block type="info" on={{click: this.onSubmit}}>确定</van-button>
                </div>
            )
        }
    },
    renderFormItem(item) {
        const paths = (item.prop || '').split('.')
        const itemValue = paths.reduce((target, path, index) => {
            // hack数组
            if (!target[path] && paths[index + 1]) {
                target[path] = {}
            }
            return target[path]
        }, this.formData)
        const {rules, ...rest} = item
        return (
            <FormElement rules={rules} value={itemValue} item={item}>
                {
                  this.isViewModel ?
                  <BaseComponentNames.Txt
                    attrs={{...rest, value: itemValue, formValue: this.formData}}
                    on={{...this.$listeners, input: (val) => this.proxyInputEvent(val, item.prop)}}
                  /> : 
                  <item.type
                    attrs={{...rest, value: itemValue, formValue: this.formData}}
                    on={{...this.$listeners, input: (val) => this.proxyInputEvent(val, item.prop)}}
                />
                }
                
            </FormElement>
        )
    },
    proxyInputEvent(value, prop, parentProp, parentIndex) {
      const formListItems = this.formList.filter(item => item.linkage)
      formListItems.forEach(item => {
          if (item.linkage) {
              if (Object.keys(item.linkage).includes(prop)) {
                  this.$set(this.formData, item.prop, null)
                  item.defaultParams = {
                      ...item.defaultParams,
                      [item.linkage[prop]]: value[this.formList.find(item => item.prop === prop).defaultProps['value']]
                  }
              }
          }
      })
      if (parentProp) {
          this.$set(this.formData[parentProp][parentIndex], prop, value)
      } else {
          const paths = prop.split('.')
          // 只满足Array<Object>
          if (paths.length > 1) {
              paths.reduce((target, path, index) => {
                  // 没有值并且还有下一个
                  if (!target[path] && paths[index + 1]) {
                      // 直接赋值无法触发vue的响应式
                      target.splice(path, 1, observable({}))
                  }

                  if (index === paths.length - 1) {
                      target[path] = value
                  }

                  return target[path]
              }, this.formData)
          } else {
              this.$set(this.formData, prop, value)
          }
      }
      this.$emit('update:formData', this.formData)
      this.$emit('change', value, prop, parentProp, parentIndex)
    },
    onSubmit() {
      this.$emit('submit', this.formData)
    },
  },
  render () {
    return (
      <div class="form-layouts-root">
        {
            this.renderComponent()
        }
        {
            this.renderShowAction()
        }
      </div>
    )
  }
}
</script>