<template>
    <div class="number-range-root">
        <p class="label-text">{{label}}</p>
        <div class="input-box">
            <van-field
                v-model="begin"
                :disabled="disabled"
                :clearable="clearable"
                :maxLength="maxLength"
                :placeholder="`请输入${label}`"
                @blur="handlerBeginInput"
            />
            <span class="text">至</span>
            <van-field
                v-model="end"
                :disabled="disabled"
                :clearable="clearable"
                :maxLength="maxLength"
                :placeholder="`请输入${label}`"
                @blur="handlerEndInput"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NumberRange',
        props: {
            label: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            maxLength: {
                type: Number,
                default: 500,
            },
            formValue: Object,
            prop: String,
            clearable: {
                type: Boolean,
                default: true,
            },
            decimalPlaces: {
                type: Number,
                default: 2,
            },
            max: {
                type: Number,
            },
        },
        data() {
            return {
                begin: undefined,
                end: undefined
            }
        },
        computed: {
            proxyValue: {
                get() {
                    return this.value
                },
                set(v) {
                    this.$emit('input', v)
                },
            },
        },
        methods: {
            handlerBeginInput() {
                let val = this.formatNumberTwoDecimalPlacesAndCommas(this.begin)
                if (this.max && this.val > this.max) {
                    val = this.max
                }
                this.formValue[`${this.prop}Start`] = val
                this.begin = val
            },
            handlerEndInput() {
                let val = this.formatNumberTwoDecimalPlacesAndCommas(this.end)
                if (this.max && this.val > this.max) {
                    val = this.max
                }
                this.formValue[`${this.prop}StartEnd`] = val
                this.end = val
            },
            formatNumberTwoDecimalPlacesAndCommas(value) {
                value = parseFloat(value)
                if (isNaN(value)) {
                    return undefined
                }
                const times = this.decimalPlaces ? Math.pow(10, this.decimalPlaces) : 1
                return (Math.round(value * times) / times).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1')
            },
        },
        created() {
            this.begin = this.formValue[`${this.prop}Start`]
            this.end = this.formValue[`${this.prop}StartEnd`]
        },
    }
</script>


<style lang="scss" scoped>
    .number-range-root {

        .input-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .label-text {
            margin: 10px 0;
            font-size: 16px;
        }

        .text {
            font-size: 14px;
        }
    }

</style>

