<template>
    <van-radio-group class="radio-group-root" v-model="value1"  @change="choose">
        <van-cell
            :title="$attrs.label"
            clickable>
            <template #right-icon>
                <van-radio :name="item.value"
                    checked-color="#00C4B3"
                    v-for="(item, index) in options"
                    :key="index"
                   >
                    {{item.label}}
                </van-radio>
            </template>
        </van-cell>
    </van-radio-group>
</template>

<script>
    export default {
        name: 'BaseRadio',
        props: {
            title: {
                type: String,
                default: '',
            },
            options: {
                type: Array,
                default() {
                    return []
                },
            },
            reverse: {
                type: Boolean,
                default: false,
            },
            value: {
                type: [String, Number],
                default: '',
            },
        },
        computed: {
        value1: {
            get () {
                return this.value
            },
            set (v) {
                this.$emit('input', v)
            }
        }
        },
        methods: {
            choose(v) {
                if (this.reverse && this.value1) {
                    this.value1 = ''
                } else {
                    this.value1 = v
                }
                // this.$emit('input', this.value1)
            },
        },
    }
</script>

<style lang="scss">
    .radio-group-root {
        width: 100%;
        .van-radio{
            & + .van-radio {
                margin-left: 8px;
            }
        }
    }
    
</style>
