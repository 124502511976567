<template>
    <Txt :value="formatValue" v-bind="$attrs"/>
</template>

<script>
    import Txt from './Txt'

    export default {
        name: 'FormFieldText', // Notice: 写组件的时候需要给一个名字
        directives: {},
        components: {
            Txt
        },
        mixins: [],
        data() {
            return {}
        },
        props: {
            formField: {
                type: Object
            },
            value: {
                type: [String, Number]
            },
        },
        computed: {
            formatValue() {
                const formField = this.formField;
                if (!formField) return this.value;
                // 字典
                if (formField.isDict) {
                    return this.value
                }
                if (formField.isEnum) {
                    return metadata.getEnumValue(formField.enumType, this.value)
                }

                return this.value
                // 根据formField来格式化显示
            }
        },
        filters: {},
        methods: {},
        watch: {},
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        beforeUpdate() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        errorCaptured() {
        },
    }
</script>

<style lang="scss" scoped>

</style>
