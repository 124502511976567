<template>
  <div class="base-title">
    {{label}}
  </div>
</template>

<script>
export default {
  name: 'BaseTitle',
  props: {
    label: {
      type: String
    }
  }
}
</script>

<style lang="scss">
</style>