<template>
  <div class="van-select">
    <van-cell v-if="!$attrs.labelImg" :is-link="!disabled" :title="label" @click="!disabled ? show = true : null" :value="displayName" />
    <van-cell v-else  :is-link="!disabled" :title="label" @click="!disabled ? show = true : null" :value="displayName">
      <template #title>
        <div class="tilte-img">
          <img :src="$attrs.labelImg" alt="">
        </div>
        <span class="custom-title bold">{{label}}</span>
      </template>
    </van-cell>
    <van-action-sheet @open="onOpen" @close="onClose" v-model="show" :title="headerName" :cancel-text="selectType === 'default' ? '取消' : ''">
      <template v-if="selectType === 'default'">
        <div class="van-action-sheet__item center"
          v-for="(item, index) in localOptions"
          :key="index"
          @click="handlerSelect(item[defaultProps.value])">{{item[defaultProps.label]}}
        </div>
      </template>
      <div class="sheet_content" v-else-if="selectType === 'ticket'">
        <van-radio-group v-model="proxyValue">
          <div class="custom__item"
            v-for="(item, index) in localOptions"
            :key="index"
            @click="handlerSelect(item[defaultProps.value])">
              <van-radio :name="item[defaultProps.value]" checked-color="#00C4B3">
                <div>
                  <p class="custom__item_title bold">{{item[defaultProps.label]}}</p>
                  <div v-html="item.tipHTML"></div>
                </div>
              </van-radio>
          </div>
        </van-radio-group>
      </div>
      <div class="sheet_content refund" v-else-if="selectType === 'refund'">
        <van-radio-group v-model="proxyValue">
          <div class="refund__item"
            v-for="(item, index) in localOptions"
            :key="index"
            :class="{active: proxyValue === item[defaultProps.value]}"
            @click="handlerSelect(item[defaultProps.value])">
              <p class="refund__item_title">{{item[defaultProps.label]}}</p>
              <van-radio :name="item[defaultProps.value]" checked-color="#00C4B3">
              </van-radio>
          </div>
        </van-radio-group>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  name: 'vanSelect',
  props: {
    label: {
        type: String,
        default: ''
    },
      disabled: {
        type: Boolean,
        default: false
    },
    options: {
      type: [Array, Function],
      default: () => []
    },
    defaultProps: {
      default: () => {return {
          label: 'label',
          value: 'value',
          children: 'children'
      }}
    },
    headerName: {
      type: String,
      default: '请选择'
    },
    value: {
      type: [String, Number, Array, Object]
    },
    contentAlign: {
      type: String,
      default: 'center'
    },
    enums: {
      type: String
    },
    selectType: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      show: false,
      actions: [],
      localOptions: [],
      optionsLoading: false,
      activeIcon: require('@/assets/icon_img_yes@2x.png'),
      inactiveIcon: require('@/assets/icon_me@2x.png'),
    }
  },
  computed: {
    displayName () {
      const o = this.options.find(item => item[this.defaultProps['value']] == this.proxyValue)
      return  o ? o[this.defaultProps['label']] : this.$attrs.placeholder ? this.$attrs.placeholder : this.label
    },
    proxyValue: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      async handler(v) {
        try {
          if (typeof v === 'function') {
            this.optionsLoading = true
            const res = await v();
            this.optionsLoading = false
            this.localOptions = res
          } else {
            this.localOptions = this.options
          }
        } catch(error) {
          this.optionsLoading = false
          console.error(error)
        }

      }
    }
  },
  methods: {
    handlerSelect (v) {
      this.proxyValue = v
      this.show = false
    },
    onOpen() {
      if (this.optionsLoading) {
        this.$toast.loading({ message: '加载中...' })
      }
    },
    onClose() {
      this.$toast.clear()
    }
  }
}
</script>

<style lang="scss">
.van-select{
  .tilte-img{
    width: 15px;
    margin-right: 9px;
    display: inline-block;
    vertical-align: text-top;
  }
  .custom__item{
    margin: 15px;
    padding: 16px;
    border: 1px #E5E5E5 solid;
    .custom__item_title{
      font-size: 15px;
      margin-bottom: 15px;
      font-weight: bold;
    }
  }
  .van-radio__icon{
    margin-right: 25px;
  }
  .refund{
    padding: 15px;
  }
  .refund__item{
    height: 40px;
    border-radius: 20px;
    border: 1px #E5E5E5 solid;
    padding-left: 15px;
    color: #1F2438;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &.active{
      background: #E5F9F7;
      color: #00C4B3;
      border-color: #E5F9F7;
    }
    .refund__item_title{
      flex: 1;
    }
  }
}

</style>
