<template>
    <div class="address-root">
        <van-cell :is-link="!disabled" :title="label" @click="show = true" :value="text" />

        <van-popup v-model="show" position="bottom">
            <van-area
                :area-list="areaList"
                @confirm="handleConfirm"
                @cancel="show = false"
            />
        </van-popup>
    </div>
</template>

<script>
    import get from 'lodash/get'
    import areaList from './area'

    export default {
        name: 'Address', // Notice: 写组件的时候需要给一个名字
        directives: {},
        components: {},
        mixins: [],
        data() {
            return {
                show: false,
                areaList,
            }
        },
        props: {
            label: String,
            value: String,
            disabled: Boolean,
            formValue: Object,
            // 从formValue上取值和赋值
            fieldsNames: {
                type: Object,
                default() {
                    return {
                        province: 'province',
                        city: 'city',
                        county: 'county',
                    }
                }
            }
        },
        computed: {
            text() {
                const province = this.formValue[this.fieldsNames.province] || '';
                const city = this.formValue[this.fieldsNames.city] || '';
                const county = this.formValue[this.fieldsNames.county] || '';
                return `${province} ${city} ${county}`
            }
        },
        filters: {},
        methods: {
            handleConfirm(result) {
                if (result.length) {
                    this.formValue[this.fieldsNames.province] = get(result, '[0].name')
                    this.formValue[this.fieldsNames.city] = get(result, '[1].name')
                    this.formValue[this.fieldsNames.county] = get(result, '[2].name')
                } else {
                    this.formValue[this.fieldsNames.province] = undefined
                    this.formValue[this.fieldsNames.city] = undefined
                    this.formValue[this.fieldsNames.county] = undefined
                }

                this.show = false
            },
            getProvinceCode(name) {
                const r = Object.entries(areaList.province_list).find(([code, text]) => {
                    return name === text
                })

                return r && r[0]
            },
            getCityCode(name) {
                const r = Object.entries(areaList.city_list).find(([code, text]) => {
                    return name === text
                })

                return r && r[0]
            },
            getCountyCode(name) {
                const r = Object.entries(areaList.county_list).find(([code, text]) => {
                    return name === text
                })

                return r && r[0]
            }
        },
        watch: {},
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        beforeUpdate() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        errorCaptured() {
        },
    }
</script>

<style lang="scss" scoped>
    .address-root {
        width: 100%;
    }
</style>
